<template>
  <div class="firm-card-box">
    <div
      class="firm-card-image"
      :style="{
        backgroundImage: `url(${logoImage})`,
      }"
    ></div>
    <div class="firm-card-info">
      <div class="title-line">
        <h1 class="firm-card-title">{{ data.name | textFilter }}</h1>
        <slot name="button"></slot>
      </div>
      <Descriptions :label-data="labelData" :data="data" :total="total" />
    </div>
  </div>
</template>

<script>
import Descriptions from "@/components/Descriptions";

export default {
  name: "FirmCard",
  components: {
    Descriptions,
  },
  props: {
    labelData: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      LAW_OFFICE: require("@/assets/service/defaultImage1.png"),
      ARBITRATION_BOARD: require("@/assets/service/defaultImage2.png"),
      CONCILIATION_BOARD: require("@/assets/service/defaultImage3.png"),
      NOTARY_OFFICE: require("@/assets/service/defaultImage4.png"),
      JUDICIAL_EXPERTISE: require("@/assets/service/defaultImage5.png"),
      LAW_HELPER: require("@/assets/service/defaultImage6.png"),
    };
  },
  computed: {
    logoImage() {
      const sourceType = this.$route.query.sourceType;
      let result = this[sourceType];
      const logoImage = this.data.logoImage;
      if (this.data.logoImage) {
        result = `url(data:image/png;base64,${logoImage})`;
      }
      return result;
    },
  },
  methods: {
    mounted() {
      print("mounted");
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.firm-card-box {
  box-sizing: border-box;
  width: 1200px;
  padding: 30px 30px 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: stretch;
  background: #fff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
}
.title-line {
  display: flex;
  justify-content: space-between;
}
.firm-card-title {
  font-size: 20px;
  font-weight: bold;
  margin: 12px 0;
}
.firm-card-image {
  width: 198px;
  height: 198px;
  background-color: #eee;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  background-size: cover;
}
.firm-card-info {
  width: 900px;
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
