<template>
  <div class="lawyerDetails">
    <Breadcrumb :data="breData" />
    <FirmCard
      v-loading="loading"
      :label-data="labelData"
      :data="organizationDetailsData"
      :total="pageInfo.total"
    >
      <template
        v-if="this.$route.query.sourceType === 'LAW_OFFICE'"
        slot="button"
      >
        <el-button
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="handleConsultVisible"
        >
          <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
            {{ $t("249393c") }}
          </span>
        </el-button>
      </template>
    </FirmCard>

    <div v-if="teamData" class="teamContainer">
      <Title :title="title" />
      <div
        v-if="
          organizationPersonsDetailsData.content &&
          organizationPersonsDetailsData.content.length
        "
        class="container"
      >
        <el-row class="profileCardRow">
          <el-col
            v-for="(item, index) of organizationPersonsDetailsData.content"
            :key="index"
            :span="item.span || 8"
          >
            <ProfileCard :team-data="teamData" :data="item" />
          </el-col>
        </el-row>
        <Pagination
          class="pagination"
          :page-info="pageInfo"
          @changePage="changePage"
        />
      </div>
      <Empty v-else type="serviceContent" />
    </div>

    <ConsultModal
      v-if="consultVisible"
      :id="organizationDetailsData.id"
      :visible="consultVisible"
      :type="type"
      @close="consultVisible = false"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import FirmCard from "@/components/FirmCard";
import Title from "@/components/Title";
import Pagination from "@/components/Pagination";
import ProfileCard from "./components/ProfileCard";
import ConsultModal from "@/components/ConsultModal";
import { isLogin } from "@/utils/jurisdiction";
import { mapGetters, mapActions } from "vuex";
import Empty from "@/components/Empty/Factory";
export default {
  name: "LawyerDetails",
  components: {
    Breadcrumb,
    Title,
    FirmCard,
    ProfileCard,
    Pagination,
    ConsultModal,
    Empty,
  },
  data() {
    return {
      type:
        this.$route.query.type === "true" ? "GRAB_LAW_OFFICE" : "LAW_OFFICE",
      loading: true,
      consultVisible: false,
      title: "律师团队",
      row: 3,
      pageInfo: {
        currentPage: 1,
        size: 9,
        total: 1,
      },
      breData: [],
      detailsData: {
        arr: [{}, {}],
      },
      labelData: [],
      teamData: [],
    };
  },
  computed: {
    ...mapGetters([
      "organizationDetailsData",
      "organizationPersonsDetailsData",
    ]),
  },
  watch: {
    organizationPersonsDetailsData(newVal) {
      if (newVal) {
        this.pageInfo.total = newVal.page.total;
      }
    },
  },
  created() {
    // this.getData()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeDefalutData();
    });
  },
  methods: {
    ...mapActions(["getOrganizationDetails", "getOrganizationPersonsDetails"]),
    handleConsultVisible() {
      if (isLogin()) {
        this.consultVisible = true;
      } else {
        this.$router.push("/account/login");
      }
    },
    getData() {
      const id = this.$route.query.id;
      const type = this.$route.query.type;
      this.getOrganizationDetails({
        id,
        type,
      }).then((res) => {
        this.loading = false;
      });

      if (this.teamData) {
        this.getPersonsDetails();
      }
    },
    getPersonsDetails() {
      const id = this.$route.query.id;
      const type = this.$route.query.type;
      this.getOrganizationPersonsDetails({
        id: id,
        type: type,
        size: this.pageInfo.size,
        page: this.pageInfo.currentPage,
      });
    },
    changeDefalutData() {
      const type = this.$route.query.sourceType;
      this.createLabelData(type);
      this.createamData(type);
      this.createBreData(type);
      this.createTitle(type);
      this.getData();
    },
    createTitle(type) {
      const obj = {
        LAW_OFFICE: "律师团队",
        CONCILIATION_BOARD: "调解员团队",
        NOTARY_OFFICE: "公证团队",
        JUDICIAL_EXPERTISE: "鉴定团队",
      };
      this.title = obj[type];
    },
    createLabelData(type) {
      const obj = {
        LAW_OFFICE: [
          { label: "统一社会信用代码", key: "nsrsbh" },
          { label: "联系电话", key: "telephone" },
          { label: "成立时间", key: "approvatDate" },
          { label: "团队规模", key: "teamSize" },
          { label: "擅长领域", key: "businessExpertise" },
          { label: "联系地址", key: "address" },
          { label: "联系邮箱", key: "email" },
          { label: "涉外国家", key: "foreignCountry" },
          { label: "服务领域", key: "serviceArea" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
        ARBITRATION_BOARD: [
          { label: "负责人", key: "personInCharge" },
          { label: "机构地址", key: "address" },
          { label: "成立时间", key: "approvatDate" },
          { label: "联系电话", key: "telephone" },
          { label: "邮编", key: "postcode" },
          { label: "仲裁员人数", key: "teamSize" },
          { label: "所属地区", key: "administrativeRegion" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
        CONCILIATION_BOARD: [
          { label: "负责人", key: "personInCharge" },
          { label: "联系地址", key: "address" },
          { label: "成立时间", key: "approvatDate" },
          { label: "联系电话", key: "telephone" },
          { label: "调解员人数", key: "teamSize" },
          { label: "所属地区", key: "administrativeRegion" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
        NOTARY_OFFICE: [
          { label: "执业证号", key: "licenseNumber" },
          { label: "联系地址", key: "address" },
          { label: "成立时间", key: "approvatDate" },
          { label: "联系电话", key: "telephone" },
          { label: "所属地区", key: "country" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
        JUDICIAL_EXPERTISE: [
          { label: "许可证号", key: "licenseNo" },
          { label: "执业司法鉴定人", key: "judicialExpert" },
          { label: "负责人", key: "personInCharge" },
          { label: "所属地区", key: "administrativeRegion" },
          { label: "联系电话", key: "telephone" },
          { label: "业务范围", key: "scopeOfBusiness" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
        LAW_HELPER: [
          { label: "邮编", key: "postcode" },
          { label: "联系地址", key: "address" },
          { label: "办公时间", key: "startEndTime" },
          { label: "所属地区", key: "administrativeRegion" },
          { label: "联系电话", key: "telephone" },
          {
            label: "机构简介",
            key: "briefIntroduction",
          },
        ],
      };
      this.labelData = obj[type];
    },
    createamData(type) {
      const obj = {
        LAW_OFFICE: [
          { label: "联系电话", key: "telephone" },
          { label: "联系邮箱", key: "mail" },
          { label: "服务领域", key: "serviceArea" },
          { label: "涉外国家", key: "foreignCountry" },
        ],
        CONCILIATION_BOARD: [{ label: "执业证号", key: "licenseNumber" }],
        NOTARY_OFFICE: [{ label: "执业证号", key: "licenseNumber" }],
        JUDICIAL_EXPERTISE: [{ label: "执业证号", key: "licenseNumber" }],
      };
      this.teamData = obj[type];
    },
    createBreData(type) {
      const result = [
        {
          name: "涉外政务专区",
          path: "/service/lawyer",
        },
      ];
      const obj = {
        LAW_OFFICE: [
          {
            name: "律师事务所",
            path: "/service/lawyer",
          },
          {
            name: "律所详情",
            path: "",
          },
        ],
        ARBITRATION_BOARD: [
          {
            name: "要仲裁",
            path: "/service/arbitration",
          },
          {
            name: "仲裁机构详情",
            path: "",
          },
        ],
        CONCILIATION_BOARD: [
          {
            name: "找调解",
            path: "/service/mediate",
          },
          {
            name: "人民调解组织详情",
            path: "",
          },
        ],
        NOTARY_OFFICE: [
          {
            name: "办公证",
            path: "/service/notarization",
          },
          {
            name: "公证机构详情",
            path: "",
          },
        ],
        JUDICIAL_EXPERTISE: [
          {
            name: "寻鉴定",
            path: "/service/appraisal",
          },
          {
            name: "鉴定机构详情",
            path: "",
          },
        ],
        LAW_HELPER: [
          {
            name: "求法援",
            path: "/service/legalAid",
          },
          {
            name: "法援机构详情",
            path: "",
          },
        ],
      };
      this.breData = result.concat(obj[type]);
    },
    changePage(val) {
      this.pageInfo.currentPage = val;
      this.getPersonsDetails();
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.lawyerDetails {
  width: @uni-width;
  margin: 0 auto;
  .teamContainer {
    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 30px 30px 40px;
      background: #fff;
      .profileCardRow {
        width: 100%;
      }
      .profileCard {
        width: 367px;
        height: 186px;
      }
      .pagination {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
