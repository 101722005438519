<template>
  <div class="profile-card-box">
    <div
      class="profile-card-image"
      :style="{
        backgroundImage: `url(${defaultImage})`,
      }"
    ></div>
    <div class="profile-card-info">
      <h1 @click="jumpDetail(data.id, data.type)">
        {{ data.name | textFilter }}
      </h1>
      <p class="title">{{ data.practiceOrganization | textFilter }}</p>
      <p v-for="(item, index) of teamData" :key="index">
        {{ item.label }}：{{ data[item.key] | textFilter }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileCard",
  props: {
    teamData: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultImage: require("@/assets/service/icon0.png"),
    };
  },
  computed: {},
  methods: {
    jumpDetail(id, type) {
      const sourceType = this.$route.query.sourceType;
      if (sourceType !== "LAW_OFFICE") {
        return false;
      }
      this.$router.push({
        path: `/communication/lawyer/detail?id=${id}&type=${type}&sorceType=lawer`,
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.profile-card-box {
  box-sizing: border-box;
  width: 367px;
  height: 240px;
  padding: 35px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: stretch;
  border: 1px solid #e5e8ed;
}

.profile-card-image {
  width: 87px;
  height: 116px;
  background-color: #eee;
  border-radius: 5px;
  //flex-grow: 0;
  //flex-shrink: 0;
  background-size: cover;
  background-position: center;
}
.profile-card-info {
  width: 218px;
  margin-left: 20px;
  //flex-grow: 1;
  //display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 20px;
    color: black;
    margin-bottom: 14px;
    margin-top: 5px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-size: 14px;
    color: #666666;
    margin-top: 6px;
    //padding: 6px 0;
  }
  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
