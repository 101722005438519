<template>
  <div>
    <el-row>
      <el-col
        v-for="(item, index) of labelData"
        :key="index"
        :span="index === labelData.length - 1 ? 24 : 12"
      >
        <DescItem :label="item.label" :link="data.link">
          {{ handleData(item.key, data) | textFilter }}
        </DescItem>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DescItem from './Item'

export default {
  name: 'Descriptions',
  components: {
    DescItem,
  },
  props: {
    labelData: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleData(key, data) {
      let result = data[key]
      if (key === 'judicialExpert' && result) {
        result = result.length + '人'
      } else if (key === 'teamSize' && this.total) {
        result = this.total
      } else if (key === 'startEndTime' && data.startTime && data.endTime) {
        result = `周一至周五 ${data.startTime.slice(10)}-${data.endTime.slice(10)}`
      } else if (key === 'country') {
        result = data.country === '国外' ? data.country : data.administrativeRegion
      }
      return result
    },
  },
}
</script>
